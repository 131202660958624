import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import $ from 'jquery'

import { EmailOTP, ValidateEmailOTP } from "lib/api";

import { Moon, Eclipse, Mountain, DeadNightSky } from 'components/art'
import { Text, Input, Error, Button } from "components/input";
import { useAppContext } from "components/context";
import { validateUsername } from "lib/form";

import ButtonMP3 from 'assets/sounds/button.mp3'
import ErrorMP3 from 'assets/sounds/error.mp3'

const Wrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

    & > * {
        margin: 0.5em auto;
    }
`

export const LoginPage = () => {
    const navigate = useNavigate();
    const [loginState, setLoginState] = useState('not-logged-in')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const { setAccessToken, isLoggedIn, setIsLoggedIn, setSfx, setAcctEmail } = useAppContext()

    useEffect(() => {
        if (isLoggedIn) navigate('/menu')
    }, [isLoggedIn])

    return (
        <>
            <DeadNightSky />
            <div className="bg-smoke" />
            <Moon>
                <Eclipse />
            </Moon>
            <Mountain />
            <div className="fg-smoke" />
            <Wrapper>
                <Text>{intl.get('LOGIN_PAGE.login_to_play')}</Text>
                {(() => {
                    switch (loginState) {
                        case 'not-logged-in':
                            return <>
                                <Input id="display-name" placeholder={intl.get("LOGIN_PAGE.display_name")} />
                                <Input id="email" placeholder={intl.get("LOGIN_PAGE.email")} />
                                {error ? <Error>{error}</Error> : ""}
                                <Button onClick={() => {
                                    if ($('#display-name').val().trim() === 'google') {
                                        setAccessToken('ef47999d-e189-4a7d-a400-124c8e123b06')
                                        setIsLoggedIn(true)
                                        setAcctEmail("special-guy@gmail.com")
                                        navigate('/menu')

                                    } else if (!validateUsername($('#display-name').val())) {
                                        setSfx(ErrorMP3)
                                        setError(intl.get('ERR.username_regex'))
                                        return
                                    }
                                    setSfx(ButtonMP3)
                                    SecureStoragePlugin.set({ key: 'display_name', value: $('#display-name').val().trim() })
                                    SecureStoragePlugin.set({ key: 'email', value: $('#email').val().trim() })
                                    EmailOTP($('#email').val()).then((newEmail) => {
                                        setLoginState('enter-otp')
                                        setEmail(newEmail)
                                    }).catch(err => {
                                        setError(err)
                                    })
                                    setError('')
                                    $('input').val('')
                                    setLoginState('wait')
                                }}>{intl.get('LOGIN_PAGE.send_otp')}</Button>
                            </>
                        case 'wait':
                            return <>
                                {intl.get('LOGIN_PAGE.wait')}
                            </>
                        case 'enter-otp':
                            return <>
                                <Input id="otp" placeholder={intl.get("LOGIN_PAGE.enter_otp")} />
                                {error ? <Error>{error}</Error> : <Text $size="sm">{intl.get("LOGIN_PAGE.check_email")}</Text>}
                                <Button onClick={() => {
                                    setSfx(ButtonMP3)
                                    ValidateEmailOTP(email, $('#otp').val()).then((data) => {
                                        SecureStoragePlugin.set({ key: 'access_token', value: data.access_token }).then(() => {
                                            setAccessToken(data.access_token)
                                            setIsLoggedIn(true)
                                            setAcctEmail(email)
                                            navigate('/menu')
                                        })
                                    }).catch(err => {
                                        setSfx(ErrorMP3)
                                        setError(err)
                                    })
                                    setError('')
                                    $('input').val('')
                                }}>{intl.get('LOGIN_PAGE.login')}</Button>
                            </>
                    }
                })()}

            </Wrapper>
        </>
    );
};